import React from 'react'
import { Link } from 'react-router-dom'
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'

export default function Footer() {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const handlePrivacyPdfClick = (e) => {
    e.preventDefault();
    window.open('/pdf/ORL_GDPR_Zasady spracovania a ochrany osobnych udajov_v1.pdf', '_blank');
  };

  const handleCookiesPdfClick = (e) => {
    e.preventDefault();
    window.open('/pdf/ORL_Zasady_pouzivania_suborov_cookies.pdf', '_blank');
  };

  return (
    <footer className="bg-gray-900 text-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-3 gap-16">
        <div>
          <h3 className="text-xl font-semibold mb-6">O nás</h3>
          <p className="text-gray-300 text-base leading-relaxed">
            Naším cieľom je poskytovať efektívne a ekologické riešenia pre Vaše zariadenia. Zameriavame sa na čistotu a prevenciu ďalšieho znečistenia. S naším tímom expertov a pokročilými technológiami Vám zabezpečíme rýchle a spoľahlivé služby, ktoré šetria čas, náklady a chránia životné prostredie.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-6">Menu</h3>
          <ul className="space-y-3">
            {[
              { name: 'Blog', path: '/blog' },
              { name: 'O nás', path: '/about' },
              { name: 'Kontakt', path: '/contact' },
              { 
                name: 'Ochrana osobných údajov', 
                path: '#', 
                onClick: handlePrivacyPdfClick,
                isPdf: true 
              },
              { 
                name: 'Používanie súborov Cookies', 
                path: '#', 
                onClick: handleCookiesPdfClick,
                isPdf: true 
              }
            ].map((link, index) => (
              <li key={index}>
                {link.isPdf ? (
                  <a
                    href="#"
                    className="text-gray-300 hover:text-orange-500 hover:underline transition duration-300 text-base"
                    onClick={link.onClick}
                  >
                    {link.name}
                  </a>
                ) : (
                  <Link
                    to={link.path}
                    className="text-gray-300 hover:text-orange-500 hover:underline transition duration-300 text-base"
                    onClick={handleLinkClick}
                  >
                    {link.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-6">Kontakt</h3>
          <ul className="space-y-3 text-gray-300 text-base">
            <li className="flex items-center">
              <FaMapMarkerAlt className="mr-3 text-orange-500" />
              Lazaretská 3A, 811 08 Bratislava - Staré Mesto
            </li>
            <li className="flex items-center">
              <FaPhone className="mr-3 text-orange-500" />
              Telefón: +421 948 209 047
            </li>
            <li className="flex items-center">
              <FaEnvelope className="mr-3 text-orange-500" />
              Email: info@cistimeorl.sk
            </li>
          </ul>
          <p className="mt-6 text-orange-500 font-semibold">
            Kontaktujte nás pre bezplatnú konzultáciu!
          </p>
        </div>
      </div>
    </footer>
  )
}
